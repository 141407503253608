import styled from 'styled-components';
import {
  SelectField,
  StringField,
  phoneRegex,
} from '../../../common';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const Container = styled.div`
  display: grid;
  gap: 1.5rem;
  overflow: auto;
`;

const StreetRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    grid-template-columns: 3fr 1fr;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    grid-template-columns: 1fr 2fr;
  }
`;

const validationSchema = (t) =>
  Yup.object().shape({
    organisationName: Yup.string().required(
      t('common:form.validation.organizationName.required')
    ),
    streetName: Yup.string().required(
      t('common:form.validation.streetName.required')
    ),
    streetNumber: Yup.string().required(
      t('common:form.validation.streetNumber.required')
    ),
    zip: Yup.string().required(t('common:form.validation.zip.required')),
    city: Yup.string().required(t('common:form.validation.city.required')),
    country: Yup.object()
      .nullable(true)
      .required(t('common:form.validation.country.required')),
    sector: Yup.object()
      .nullable(true)
      .required(t('common:form.validation.industry.required')),
    phone: Yup.string()
      .required(t('common:form.validation.phone.required'))
      .matches(phoneRegex, t('common:form.validation.phone.matches')),
  });

export const ProfileModalForm = React.forwardRef(
  ({ sectorOptions, countryOptions, defaultValues, submit }, ref) => {
    const { t } = useTranslation();

    const {
      register,
      control,
      handleSubmit,
      formState: { errors },
    } = useForm({
      defaultValues,
      resolver: yupResolver(validationSchema(t)),
    });

    return (
      <form ref={ref} onSubmit={handleSubmit(submit)}>
        <Container>
          <StringField
            register={register}
            error={errors?.organisationName?.message}
            label={t('common:form.label.organizationName')}
            name="organisationName"
            placeholder={t('common:form.placeholder.organizationName')}
          />
          <StreetRow>
            <StringField
              register={register}
              error={errors?.streetName?.message}
              label={t('common:form.label.streetName')}
              name="streetName"
              placeholder={t('common:form.placeholder.streetName')}
            />
            <StringField
              register={register}
              error={errors?.streetNumber?.message}
              label={t('common:form.label.streetNumber')}
              name="streetNumber"
              placeholder={t('common:form.placeholder.streetNumber')}
            />
          </StreetRow>
          <Row>
            <StringField
              register={register}
              error={errors?.zip?.message}
              label={t('common:form.label.zip')}
              name="zip"
              placeholder={t('common:form.placeholder.zip')}
            />
            <StringField
              register={register}
              error={errors?.city?.message}
              label={t('common:form.label.city')}
              name="city"
              placeholder={t('common:form.placeholder.city')}
            />
          </Row>
          <SelectField
            error={errors?.country?.message}
            label={t('common:form.label.country')}
            name="country"
            options={countryOptions}
            placeholder={t('common:form.placeholder.country')}
            control={control}
            menuPortalTarget={document.body}
            styleOverwrite={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />

          <SelectField
            error={errors?.sector?.message}
            label={t('common:form.label.industry')}
            placeholder={t('common:form.placeholder.industry')}
            control={control}
            name="sector"
            options={sectorOptions}
            menuPortalTarget={document.body}
            styleOverwrite={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />

          <StringField
            label={t('common:form.label.phone')}
            placeholder={t('common:form.placeholder.phone')}
            name="phone"
            error={errors?.phone?.message}
            register={register}
          />
        </Container>
      </form>
    );
  }
);
