import gql from 'graphql-tag';

export const GET_ZONES = gql`
  query getOrganizationsWithZones {
    organizations {
      id
      name
      zones {
        id
        name
        location {
          id
          name
        }
        currentSubscription {
          id
          subscriptionLines {
            id
            product {
              id
              isAddon
              shortName
              name
              level
            }
          }
        }
      }
    }
  }
`;

export const GET_CUSTOM_PLAYLISTS_OF_ZONE = gql`
  query getCustomPlaylistsOfZone($zoneId: ID!) {
    customPlaylistsOfZone(zoneId: $zoneId) {
      timestamp
      customPlaylists {
        id
        title
        description
        timestamp
      }
    }
  }
`;

export const GET_FAVOURITE_CUSTOM_PLAYLIST_OF_ZONE = gql`
  query getFavouriteCustomPlaylistOfZone($zoneId: ID!) {
    favouriteCustomPlaylistOfZone(zoneId: $zoneId) {
      id
      title
      description
      timestamp
    }
  }
`;

export const CREATE_CUSTOM_PLAYLIST = gql`
  mutation createCustomPlaylist(
    $zoneId: ID!
    $customPlaylistsTimestamp: Float!
    $playlist: CustomPlaylistInput!
  ) {
    createCustomPlaylist(
      zoneId: $zoneId
      customPlaylistsTimestamp: $customPlaylistsTimestamp
      playlist: $playlist
    ) {
      updateInfos {
        zoneId
      }
      customPlaylistsTimestamp
      playlist {
        id
        title
        description
        timestamp
      }
    }
  }
`;

export const GET_SONGS_OF_PLAYLIST = gql`
  query getSongsOfPlaylist($playlistId: ID!, $zoneId: ID!) {
    songsOfPlaylist(playlistId: $playlistId, zoneId: $zoneId) {
      id
      duration
      group
      location
      title
      composer
      bpm
      musicFeel
      version
      year
      mood
      dancingStyle
      startAudioSignal
      endAudioSignal
      fadeBeginPosition
      musicStyle
      subStyle
      genreDescription
      rhythmDescription
    }
  }
`;

export const GET_SONGS_OF_MUSIC_COLLECTION = gql`
  query getSongsOfMusicCollection(
    $musicCollectionId: ID!
    $musicChannelId: ID!
    $zoneId: ID!
    $changeableParameters: [ChangeableParameterInput!]
  ) {
    songsOfMusicCollection(
      musicCollectionId: $musicCollectionId
      musicChannelId: $musicChannelId
      zoneId: $zoneId
      changeableParameters: $changeableParameters
    ) {
      id
      duration
      group
      location
      title
      composer
      bpm
      musicFeel
      version
      year
      mood
      dancingStyle
      startAudioSignal
      endAudioSignal
      fadeBeginPosition
      musicStyle
      subStyle
      genreDescription
      rhythmDescription
    }
  }
`;

export const EDIT_CUSTOM_PLAYLIST = gql`
  mutation editCustomPlaylist($zoneId: ID!, $playlist: CustomPlaylistInput!) {
    editCustomPlaylist(zoneId: $zoneId, playlist: $playlist) {
      timestamp
    }
  }
`;

export const DELETE_CUSTOM_PLAYLIST = gql`
  mutation deleteCustomPlaylist(
    $zoneId: ID!
    $customPlaylistsTimestamp: Float!
    $playlistId: ID!
  ) {
    deleteCustomPlaylist(
      zoneId: $zoneId
      customPlaylistsTimestamp: $customPlaylistsTimestamp
      playlistId: $playlistId
    ) {
      updateInfos {
        zoneId
      }
      customPlaylistsTimestamp
    }
  }
`;

export const ADD_SONGS_TO_CUSTOM_PLAYLIST = gql`
  mutation addSongsToCustomPlaylist(
    $zoneId: ID!
    $playlistId: ID!
    $timestamp: Float!
    $songIds: [ID!]!
    $duplicateHandling: String!
  ) {
    addSongsToCustomPlaylist(
      zoneId: $zoneId
      playlistId: $playlistId
      timestamp: $timestamp
      songIds: $songIds
      duplicateHandling: $duplicateHandling
    ) {
      success
      duplicatesAmount
      timestamp
      updateInfos {
        zoneId
      }
    }
  }
`;

export const GET_MUSIC_CHANNEL_GROUPS = gql`
  query getMusicChannelGroups($zoneId: ID!, $language: String!) {
    musicChannelGroups(zoneId: $zoneId, language: $language) {
      id
      name
      musicChannels {
        id
        name
        musicChannelGroupId
        musicCollections {
          id
          title
        }
      }
    }
  }
`;

export const SEARCH_SONGS_BY_VALUE = gql`
  query searchSongsByValue(
    $zoneId: ID!
    $isSuggestion: Boolean!
    $value: String
    $type: String
    $songId: ID
  ) {
    searchSongsByValue(
      input: {
        zoneId: $zoneId
        isSuggestion: $isSuggestion
        value: $value
        type: $type
        songId: $songId
      }
    ) {
      id
      duration
      group
      location
      title
      composer
      bpm
      musicFeel
      version
      year
      mood
      dancingStyle
      startAudioSignal
      endAudioSignal
      fadeBeginPosition
      musicStyle
      subStyle
      genreDescription
      rhythmDescription
    }
  }
`;

export const AUTOCOMPLETION_SONGS_AND_ARTISTS = gql`
  query autocompletionSongsAndArtists($value: String!) {
    autocompletionSongsAndArtists(value: $value) {
      artists {
        id
        source
      }
      songs {
        id
        source
      }
    }
  }
`;

export const SAVE_CUSTOM_PLAYLIST = gql`
  mutation saveCustomPlaylist(
    $zoneId: ID!
    $playlistId: ID!
    $playlist: SaveCustomPlaylistInput!
  ) {
    saveCustomPlaylist(
      zoneId: $zoneId
      playlistId: $playlistId
      playlist: $playlist
    )
  }
`;

export const COPY_PLAYLISTS_TO_OTHER_ZONES = gql`
  mutation copyPlaylistsToOtherZones(
    $currentZoneId: ID!
    $zoneIds: [ID!]!
    $playlistIds: [ID!]!
  ) {
    copyPlaylistsToOtherZones(
      currentZoneId: $currentZoneId
      zoneIds: $zoneIds
      playlistIds: $playlistIds
    ) {
      updateInfos {
        zoneId
      }
      sourcePlaylistIds
      destinationZoneIds
    }
  }
`;

export const GET_SONG_MEDIA_URL = gql`
  query getSongMediaUrl($songId: ID!) {
    songMediaUrl(songId: $songId)
  }
`;

export const GET_MUSIC_COLLECTION_TWEAK_STRENGTH_BY_ZONE = gql`
  query getMusicCollectionTweakStrengthByZone(
    $musicCollectionId: ID!
    $zoneId: ID!
    $changeableParameters: [ChangeableParameterInput!]!
  ) {
    musicCollectionTweakStrengthByZone(
      musicCollectionId: $musicCollectionId
      zoneId: $zoneId
      changeableParameters: $changeableParameters
    ) {
      percentage
      status
    }
  }
`;

export const GET_MUSIC_COLLECTION_CONTEXT_BY_ZONE = gql`
  query getMusicCollectionContextByZone($musicCollectionId: ID!, $zoneId: ID!) {
    musicCollectionContextByZone(
      musicCollectionId: $musicCollectionId
      zoneId: $zoneId
    ) {
      type
      id
      title
      description
      timestamp
      changeableParameter {
        type
        name
        metadataDisplayType
        metadataDisplayName
        value {
          value
          selected
        }
        minimum
        maximum
        step
        lowerSelection
        upperSelection
        metadataDisplayMinimum
        metadataDisplayMaximum
      }
      defaultChangeableParameter {
        type
        name
        metadataDisplayType
        metadataDisplayName
        value {
          value
          selected
        }
        minimum
        maximum
        step
        lowerSelection
        upperSelection
        metadataDisplayMinimum
        metadataDisplayMaximum
      }
    }
  }
`;

export const GET_PLAYER_LINK = gql`
  query getPlayerLink($zoneId: ID!) {
    playerLink(zoneId: $zoneId)
  }
`;
