import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';

import {
  LoadingIndicator,
  ErrorMessage,
  getProductSubscriptionLineOfSubscriptionLines,
  Button,
} from '../../../common';
import PlaylistsWrapper from './playlistsWrapper';
import { GET_ZONES } from './api';
import { ReactComponent as PlaylistsLarge } from '../../../assets/icons/navigation/playlistsLarge.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import NoOrangeInfo from './noOrangeInfo';
import { PlaylistsManager } from './playlistsProvider';
import ZoneSelection from './zoneSelection';
import theme from '../../../global/style/theme';

const Container = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;
`;

const Header = styled.div`
  display: grid;
  background-color: ${({ theme }) => theme.white};
  grid-template-rows: 1fr min-content min-content;
  padding: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 2.2rem 3rem;
    gap: 3rem;
    grid-template-columns: 1fr min-content;
    grid-template-rows: unset;
    grid-auto-flow: column;
    align-items: center;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 3.2rem 4rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 4rem 4.8rem;
  }
`;

const Divider = styled.div`
  grid-column: span 2;
  width: 100%;
  height: 0.1rem;
  background-color: ${({ theme }) => theme.stroke};
  margin: 1.5rem -1.5rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    display: none;
  }
`;

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1.6rem;
  grid-column: span 2;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-column: unset;
  }
`;

const Logo = styled.div`
  width: 4rem;
  height: 4rem;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.dark500};
  border-radius: 4px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    width: 4.8rem;
    height: 4.8rem;
  }
`;

const StyledPlaylistsLarge = styled(PlaylistsLarge)`
  width: 2.6rem;
  height: 2.6rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    width: 3.2rem;
    height: 3.2rem;
  }
`;

const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 600;
  white-space: nowrap;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    font-size: 2.8rem;
  }
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 2rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 2rem;
`;

const Grid = styled.div`
  height: 100%;
  display: grid;
  grid-auto-rows: min-content;
  padding: 2rem 1.5rem;
  overflow-y: auto;
  gap: 0.5rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 2rem 3rem;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 2rem 4rem;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 2rem 4.8rem;
  }
`;

const ZonesWrapper = styled.div`
  width: min-content;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 0.8rem;
  white-space: nowrap;
`;

const startTransitionValue = { scale: 0, opacity: 0 };
const endTransitionValue = { scale: 1, opacity: 1 };
const transition = { ease: 'easeOut', duration: 0.3 };

const PlaylistsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [playlistsModalIsOpen, setPlaylistsModal] = useState(false);

  const {
    loading: zonesLoading,
    error: zonesError,
    data: zonesData,
  } = useQuery(GET_ZONES);

  const zonesWithOrangeOrRoyaltyFree = useMemo(() => {
    const zonesWithOrangeProduct = [];
    if (zonesData?.organizations) {
      zonesData.organizations.forEach((organization) => {
        if (organization?.zones) {
          organization.zones.forEach((zone) => {
            if (zone?.currentSubscription?.subscriptionLines) {
              const product = getProductSubscriptionLineOfSubscriptionLines(
                zone?.currentSubscription?.subscriptionLines
              );
              console.log(product?.product?.shortName, product?.product?.level);
              if (product?.product?.level === 300) {
                zonesWithOrangeProduct.push({
                  ...zone,
                  organizationName: organization.name,
                });
              }
            }
          });
        }
      });
    }
    return zonesWithOrangeProduct;
  }, [zonesData]);

  const handleImportPlaylists = useCallback(() => {
    navigate('/integrations/spotify/import');
  }, [navigate]);

  const handleOpenPlaylists = useCallback(() => {
    setPlaylistsModal(true);
  }, []);

  const handleClosePlaylists = useCallback(() => {
    setPlaylistsModal(false);
  }, []);

  return (
    <Container>
      <Header>
        <TitleContainer>
          <Logo>
            <StyledPlaylistsLarge color={theme.white} />
          </Logo>
          <Title>{t('musicManagement:playlists.title')}</Title>
        </TitleContainer>
        {!zonesError &&
        !zonesLoading &&
        zonesWithOrangeOrRoyaltyFree?.length > 0 ? (
          <>
            <Divider />
            <ZonesWrapper>
              <DownloadIcon
                width="1.6rem"
                height="1.6rem"
                color={theme.dark300}
              />
              <Button
                emphasis="secondary"
                size="medium"
                onClick={handleImportPlaylists}
                title={t('integrations:spotify.import.importButtonTitle')}
              >
                {t('common:button.spotifyImport')}
              </Button>
            </ZonesWrapper>
          </>
        ) : null}
      </Header>
      <Grid>
        {!zonesError &&
        !zonesLoading &&
        zonesWithOrangeOrRoyaltyFree?.length > 0 ? (
          <PlaylistsManager zones={zonesWithOrangeOrRoyaltyFree}>
            <ZoneSelection onConfirm={handleOpenPlaylists} />
            <AnimatePresence>
              {playlistsModalIsOpen ? (
                <PlaylistsWrapper
                  animate={endTransitionValue}
                  exit={startTransitionValue}
                  initial={startTransitionValue}
                  onClose={handleClosePlaylists}
                  transition={transition}
                />
              ) : null}
            </AnimatePresence>
          </PlaylistsManager>
        ) : null}
        {zonesLoading ? <StyledLoadingIndicator /> : null}
        {zonesError ? <StyledErrorMessage error={zonesError} /> : null}
        {!zonesError &&
        !zonesLoading &&
        zonesWithOrangeOrRoyaltyFree?.length === 0 ? (
          <NoOrangeInfo />
        ) : null}
      </Grid>
    </Container>
  );
};

export default PlaylistsPage;
